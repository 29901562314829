<template>
  <section id="crm-user-list">
    <b-breadcrumb
      v-if="showFilters"
      :items="breadcrumb"
    />

    <b-card-actions
      v-if="showFilters"
      ref="filters"
      title="Filtros"
      :title-collapse="true"
      class="form-filters"
      :action-close="false"
      :action-refresh="false"
      :action-collapse="true"
      :collapsed="false"
      @refresh="refreshStop('cardAction')"
    >
      <user-list-filters
        :filters="filtersValues"
        :is-loading="isLoading"
        @search="listUsers"
      />
    </b-card-actions>

    <b-card-actions
      ref="users"
      :title="pageTitle"
      :title-collapse="true"
      :action-close="false"
      :action-refresh="true"
      :action-collapse="true"
      :collapsed="collapsed"
      @refresh="listUsers"
    >
      <b-row v-if="showInternalFilters">
        <b-col>
          <user-list-filters
            :filters="filtersValues"
            :is-loading="isLoading"
            @search="listUsers"
          />

          <hr />
        </b-col>
      </b-row>

      <b-row
        class="header-card"
        align-v="center"
      >
        <b-col>
          <div
            class="text-right d-flex justify-content-end mb-2"
          >
            <b-button
              v-if="accountId"
              class="ml-2"
              :title="`Vincular um usuário existente a esta conta`"
              @click="newLinkUser"
            >
              Vincular usuário existente
            </b-button>

            <b-button
              class="ml-2"
              title="`Criar um novo usuário e vincluar a conta`"
              @click="newUser"
            >
              Novo usuário
            </b-button>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-table
            :busy="isLoading || isRemoving"
            fixed
            :items="users"
            :fields="resultFields"
            sort-icon-left
            no-local-sorting
          >
            <template #head(name)>
              Nome
            </template>

            <template #head(email)>
              E-mail
            </template>

            <template #head(doc)>
              CPF/CNPJ
            </template>

            <template #head(permission_type)>
              Permissão
            </template>

            <template #head(actions)>
              Ações
            </template>

            <template #cell(doc)="row">
              <span v-if="row.item.person_type === 'pf'">{{ row.item.cpf | cpf }}</span>
              <span v-else>{{ row.item.cnpj | cnpj }}</span>
            </template>

            <template #cell(permission_type)="row">
              <span>{{ $t(`crm.account.permission_type.${row.item.permission_type}`) }}</span>
              <b-button
                variant="flat-primary"
                class="btn-icon ml-2"
                @click="changeLinkUser(row.item)"
              >
                <feather-icon
                  icon="EditIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </b-button>
            </template>

            <template #cell(actions)="row">
              <div class="text-nowrap text-center">
                <b-button
                  variant="flat-primary"
                  :disabled="isLoading || isRemoving"
                  title="Editar"
                  @click="editUser(row.item)"
                >
                  <feather-icon
                    icon="EditIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </b-button>

                <b-button
                  variant="flat-primary"
                  :title="accountId ? 'Desvincular' : 'Remover'"
                  :disabled="isLoading || isRemoving"
                  @click="remove(row.item)"
                >
                  <feather-icon
                    v-if="accountId"
                    icon="DeleteIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                  <feather-icon
                    v-else
                    icon="TrashIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </b-button>
              </div>
            </template>

          </b-table>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="6">
          <p>Mostrando {{ pages.from }} até {{ pages.to }} de {{ pages.total }} resultados</p>
        </b-col>

        <b-col cols="6">
          <!-- pagination nav -->
          <b-pagination-nav
            v-model="currentPage"
            :number-of-pages="pages.last_page"
            :link-gen="() => { return '#' }"
            align="end"
          />
        </b-col>
      </b-row>
    </b-card-actions>

    <b-modal
      ref="modal-user-edit"
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      size="lg"
    >
      <user-edit
        :type="type"
        :user-id="userIdToEdit"
        :account-id="accountId"
        @close="$refs['modal-user-edit'].hide()"
        @saved="saved"
      />
    </b-modal>

    <b-modal
      id="modal-link-user"
      ref="modal-link-user"
      :ok-title="accountUser.user_id === null ? 'Vincular' : 'Alterar'"
      cancel-title="Cancelar"
      :ok-disabled="isSubmiting"
      :cancel-disabled="isSubmiting"
      no-close-on-esc
      no-close-on-backdrop
      size="sm"
      @ok.prevent="linkUserToAccount"
    >
      <validation-observer ref="rulesAccount">
        <b-form-group
          v-if="accountUser.user_id === null"
          label="E-mail"
          label-for="email"
        >
          <validation-provider
            #default="{ errors }"
            rules="required"
            name="email"
          >
            <b-form-input
              id="email"
              ref="email"
              v-model="accountUser.email"
              name="email"
              :disabled="isSubmiting"
            />
            <small class="text-danger">{{ errors[0] }}</small>

          </validation-provider>
        </b-form-group>

        <b-form-group
          label="Permissão"
          label-for="permission_type"
        >
          <validation-provider
            #default="{ errors }"
            rules="required"
            name="permission_type"
          >
            <b-form-select
              id="permission_type"
              ref="permission_type"
              v-model="accountUser.permission_type"
              name="permission_type"
              :disabled="isSubmiting"
              :options="crmAccountUserPermissionTypes"
            />
            <small class="text-danger">{{ errors[0] }}</small>

          </validation-provider>
        </b-form-group>
      </validation-observer>

    </b-modal>
  </section>
</template>

<script>
import {
  BBreadcrumb,
  BButton,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BPaginationNav,
  BRow,
  BTable,
} from 'bootstrap-vue'
import CrmService from '@/services/crmService'
import toasts from '@/mixins/toast'
import swall from '@/mixins/swall'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import UserEdit from '@/views/crm/user/UserEdit.vue'
import UserListFilters from '@/views/crm/user/UserListFilters.vue'
import { crmAccountUserPermissionTypes } from '@/utils/options'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  components: {
    UserEdit,
    BCardActions,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BPaginationNav,
    BButton,
    BTable,
    BRow,
    BCol,
    BBreadcrumb,
    ValidationProvider,
    ValidationObserver,
    UserListFilters,
  },

  mixins: [toasts, swall],

  props: {
    collapsed: {
      type: Boolean,
      required: false,
      default: false,
    },
    editInModal: {
      type: Boolean,
      required: false,
      default: false,
    },
    showFilters: {
      type: Boolean,
      required: false,
      default: true,
    },
    showInternalFilters: {
      type: Boolean,
      required: false,
      default: false,
    },
    accountId: {
      type: Number,
      required: false,
      default: null,
    },
    title: {
      type: String,
      required: false,
      default: null,
    },
    type: {
      type: String,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      breadcrumb: [
        {
          text: 'Projetos',
          active: true,
        },
        {
          text: 'CRM',
          active: true,
        },
        {
          text: 'Usuário',
          to: { name: 'crm-user-list' },
        },
      ],
      filtersValues: {
        name: '',
        email: '',
        doc: '',
        page: 1,
      },
      userIdToEdit: null,
      pageTitle: '',
      pages: {},
      currentPage: 1,
      users: [],
      accountUser: {
        user_id: null,
        account_id: '',
        email: '',
        permission_type: '',
      },
      resultFields: [
        { key: 'name', sortable: false },
        { key: 'email', sortable: false },
        { key: 'permission_type', sortable: false },
        { key: 'doc', sortable: false },
        { key: 'actions', sortable: false, thStyle: 'width: 180px' },
      ],
      userData: null,
      isLoading: false,
      isRemoving: false,
      isSubmiting: false,
      crmAccountUserPermissionTypes,
    }
  },

  watch: {
    currentPage(page) {
      this.currentPage = page
      this.listUsers()
    },
  },

  created() {
    const userDataString = localStorage.getItem('userData')
    this.userData = JSON.parse(userDataString)
    this.pageTitle = this.title ?? 'Usuários'

    this.listUsers()
  },

  methods: {
    saved() {
      this.$refs['modal-user-edit'].hide()
      this.listUsers()
    },

    linkUserToAccount() {
      return this.$refs.rulesAccount.validate().then(success => {
        if (success) {
          this.isSubmiting = true

          if (this.accountUser.user_id === null) {
            CrmService.addUserToAccount(this.accountId, this.accountUser.email, this.accountUser.permission_type).then(() => {
              this.toastSuccess('Conta vinculdada', 'Agora o usuário já pode usar esta conta ao acessar o CRM')
              this.$refs['modal-link-user'].hide()
              this.listUsers()
            }).catch(error => {
              this.swallError('Erro!', error.response.data.message, 'Fechar')
            }).finally(() => {
              this.isSubmiting = false
            })
          } else {
            CrmService.changeUserToAccount(this.accountUser.account_id, this.accountUser.user_id, this.accountUser.permission_type).then(() => {
              this.toastSuccess('Conta alterada', 'Qualque ação do usuário já vai ser validade de acordo com sua nova permissão')
              this.$refs['modal-link-user'].hide()
              this.listUsers()
            }).catch(error => {
              this.swallError('Erro!', error.response.data.message, 'Fechar')
            }).finally(() => {
              this.isSubmiting = false
            })
          }
        }
      })
    },

    reset() {
      Object.entries(this.filtersValues).forEach(item => {
        this.filtersValues[item[0]] = ''
      })
      this.listUsers()
    },

    searchAndResetPage() {
      this.currentPage = 1
      this.listUsers()
    },

    newUser() {
      this.userIdToEdit = 0
      this.$refs['modal-user-edit'].show()
    },

    newLinkUser() {
      this.accountUser = {
        user_id: null,
        account_id: '',
        email: '',
        permission_type: '',
      }
      this.$refs['modal-link-user'].show()
    },

    changeLinkUser(item) {
      this.accountUser = {
        user_id: item.id,
        account_id: this.accountId,
        permission_type: item.permission_type,
      }
      this.$refs['modal-link-user'].show()
    },

    editUser(item) {
      this.userIdToEdit = item.id

      if (this.editInModal) {
        this.$refs['modal-user-edit'].show()
      } else {
        this.$router.push({ name: 'crm-user-edit', params: { userId: this.userIdToEdit } })
      }
    },

    remove(item) {
      if (this.accountId) {
        return this.removeAccountUser(item)
      }
      return this.removeUser(item)
    },

    removeUser(item) {
      this.$swal({
        title: 'Remover usuário?',
        text: `Deseja remover o usuário '${item.name}'?`,
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Sim, remover!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.isRemoving = true
          CrmService.removeUser(item.id).then(response => {
            if (response.data.deleted) {
              this.listUsers()
            }
          }).catch(error => {
            this.swallError('Erro!', error.response.data.message, 'Fechar')
          }).finally(() => {
            this.isRemoving = false
          })
        }
      })
    },

    removeAccountUser(item) {
      this.$swal({
        title: 'Desvincular usuário da conta?',
        text: `Deseja desvincular o usuário '${item.name}' da conta? O usuário continuará existindo, apenas não estará mais vinculado a esta conta!`,
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Sim, desvincular!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.isRemoving = true
          CrmService.removeAccountUser(this.accountId, item.id).then(response => {
            if (response.data.deleted) {
              this.listUsers()
            }
          }).catch(error => {
            this.swallError('Erro!', error.response.data.message, 'Fechar')
          }).finally(() => {
            this.isRemoving = false
          })
        }
      })
    },

    listUsers() {
      if (this.accountId !== 0) {
        this.isLoading = true

        const filters = { ...this.filtersValues }
        if (this.accountId) {
          filters.account_id = this.accountId
        }
        if (this.filtersValues.client_account_id) {
          delete this.filtersValues.client_account_id
        }

        filters.page = this.currentPage
        CrmService.listUsers(filters).then(response => {
          if (response.data !== null) {
            this.users = response.data.users.data
            this.pages = {
              ...response.data.users,
              data: null,
            }
          }
        }).finally(() => {
          this.isLoading = false
          this.$refs.users.showLoading = false
        })
      }
    },
  },
}
</script>
