<template>
  <section id="crm-user-list">
    <b-row>
      <b-col cols="3">
        <b-form-group
          label="Nome"
          label-for="name"
        >
          <b-form-input
            id="name"
            v-model="filters.name"
            @keyup.enter="searchAndResetPage"
          />
        </b-form-group>
      </b-col>

      <b-col cols="3">
        <b-form-group
          label="E-mail"
          label-for="email"
        >
          <b-form-input
            id="email"
            v-model="filters.email"
            @keyup.enter="searchAndResetPage"
          />
        </b-form-group>
      </b-col>

      <b-col cols="3">
        <b-form-group
          label="Documento (CPF/CNPJ)"
          label-for="doc"
        >
          <b-form-input
            id="email"
            v-model="filters.doc"
            @keyup.enter="searchAndResetPage"
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="3"
        class="form-buttons"
      >
        <b-form-group
          label=""
          label-for="btn-buscar"
          style="margin-top: 20px"
        >
          <b-button
            id="btn-buscar"
            variant="primary"
            @click="searchAndResetPage"
          >
            Buscar
          </b-button>

          <b-button
            id="btn-limpar"
            variant="outline-secondary"
            @click="reset()"
          >
            Limpar
          </b-button>
        </b-form-group>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BButton,
  BCol,
  BFormGroup,
  BFormInput,
  BRow,
} from 'bootstrap-vue'
import toasts from '@/mixins/toast'
import swall from '@/mixins/swall'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BButton,
    BRow,
    BCol,
  },

  mixins: [toasts, swall],

  props: {
    filters: {
      type: Object,
      required: true,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
    }
  },

  watch: {
    currentPage(page) {
      this.currentPage = page
      this.searchAndResetPage()
    },
  },

  created() {
  },

  methods: {
    reset() {
      Object.entries(this.filters).forEach(item => {
        this.filters[item[0]] = ''
      })
      this.$emit('search')
    },

    searchAndResetPage() {
      this.currentPage = 1
      this.$emit('search')
    },
  },
}
</script>
